'use strict';

import $ from 'jquery';
window.jQuery = $;
window.$ = $; // lazy fix if you want to use jQuery in your inline scripts.

import 'jquery-lazy';
import 'parsleyjs';
import 'rangeslider.js';
import 'slick-carousel';
import 'webui-popover';

require('./libs/bootstrap.min.js');
